/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import { LogoImage, NavLinks } from "@/data";
import { footerSection, footerMenu, footerBottom, footerTop } from "../assets/styles/AboutTwo.styles";
import IconMap from "@/images/LP-teamsix/map.svg";
import IconPhone from "@/images/LP-teamsix/phone.svg";
import IconInstagram from "@/images/LP-teamsix/instagram.svg";
import IcoFacebook from "@/images/LP-teamsix/facebook.svg";
import IcoYoutube from "@/images/LP-teamsix/youtube.svg";
import { Link } from "gatsby";

const AboutTwo = () => {
  return (
    <section css={footerSection}>
      <div css={footerTop}>
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <h2>
                Junte-se a nós nesta <span>jornada de transformação</span>{" "}
                pessoal e profissional
              </h2>
            </Col>
            <Col lg={1} md={1} sm={1} />
            <Col lg={5} md={4} sm={12}>
              <h3>
                <img src={IconMap} alt="" /> Faça uma visita
              </h3>
              <p style={{ marginBottom: 24 }}>
                Av. Independência, 990 - sobreloja 9, Porto Alegre
              </p>
              <h3>
                <img src={IconPhone} alt="" /> Fale conosco
              </h3>
              <p style={{ marginBottom: 24 }}>
                <a href="https://web.whatsapp.com/send?phone=5551991709380&text=" target="_blank">(51) 9 9170-9380</a></p>
              <h3>Siga as nossas redes sociais</h3>
              <a
                href="https://www.instagram.com/teamsix.elitemilitar/"
                target="_blank"
                style={{ marginRight: 8 }}
              >
                <img src={IconInstagram} alt="" />
              </a>
              <a
                href="https://www.facebook.com/teamsix.elitemilitar"
                target="_blank"
                style={{ marginRight: 8 }}
              >
                <img src={IcoFacebook} alt="" />
              </a>
              <a
                href="https://www.youtube.com/@rede_bertoni"
                target="_blank"
              >
                <img src={IcoYoutube} alt="" />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <div css={footerBottom}>
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <img src={LogoImage.light} alt="" />
            </Col>
            <Col lg={1} md={1} sm={1} />
            <Col lg={5} md={4} sm={12}>
              <div css={footerMenu}>
                <ul>
                  <li><Link to="#quem-somos">Quem somos</Link></li>
                  <li><Link to="#fundador">Conheça o fundador</Link></li>
                </ul>
                <ul>
                  <li><Link to="#matriculas">Matrículas</Link></li>
                  <li><a href="https://web.whatsapp.com/send?phone=5551991709380&text=" target="_blank">Fale conosco</a></li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default AboutTwo;
