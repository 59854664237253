import logoDefault from "../assets/images/LP-teamsix/team-six-logo.png"
import banner01 from "../assets/images/LP-teamsix/banner1.jpg"
import banner02 from "../assets/images/LP-teamsix/banner2.jpg"
import banner03 from "../assets/images/LP-teamsix/banner3.jpg"
import mobileBanner01 from "../assets/images/LP-teamsix/mobileBanner1.jpg"
import mobileBanner02 from "../assets/images/LP-teamsix/mobileBanner2.jpg"
import mobileBanner03 from "../assets/images/LP-teamsix/mobileBanner3.jpg"

export const LogoImage = {
  light: logoDefault,
};

export const NavLinks = [
  {
    name: "Quem somos",
    url: "#quem-somos",
  },
  {
    name: "Matrículas",
    url: "#matriculas",
  },
  {
    name: "Fale Conosco",
    url: "#contato",
  },
];

export const NavInnerLinks = [
  {
    name: "Quem Somos",
    url: "/#quem-somos",
  },
  {
    name: "Matrículas",
    url: "/#matriculas",
  },
  {
    name: "Fale Conosco",
    url: "/#contato",
  },
];

export const EliteData = [
  {
    countNumber: 156,
    aprovados: 'aprovados',
    curso: 'EsPCEx'
  },
  {
    countNumber: 81,
    aprovados: 'aprovados',
    curso: 'AFA'
  },
  {
    countNumber: 96,
    aprovados: 'aprovados',
    curso: 'EFOMM'
  },
];

export const SliderOneData = [
  {
    image: banner01,
    imageMobile: mobileBanner01,
    shape: 'shape01',
    subTitle: "O maior número de mulheres aprovadas em concursos militares é aqui",
    title: "+ ",
    highlight: "mulheres\n aprovadas",
    button: {
      label: "Seja Team Six",
      url: "https://web.whatsapp.com/send?phone=5551991709380&text=",
    },
    
  },
  {
    image: banner02,
    imageMobile: mobileBanner02,
    shape: 'shape01',
    subTitle: "Somos referência em aprovação nos principais concursos militares. Comece agora sua preparação!",
    title: "Seja a ",
    highlight: "elite\n militar",
    button: {
      label: "Seja Team Six",
      url: "https://web.whatsapp.com/send?phone=5551991709380&text=",
    },
    
  },
  {
    image: banner03,
    imageMobile: mobileBanner03,
    shape: 'shape01',
    subTitle: "",
    title: "",
    highlight: "ESA, EsPCEx,\n AFA, EFOMM,\n EN, IME e ITA",
    button: {
      label: "Seja Team Six",
      url: "https://web.whatsapp.com/send?phone=5551991709380&text=",
    },
    
  },
  // {
  //   image: 'sliderOne01',
  //   shape: 'shape01',
  //   subTitle: "Aprofunde o seu conhecimento das matérias mais cobradas pelo governo federal e as principais instituições. É para você e é gratuita.",
  //   title: "A única olimpíada 100% \n focada ",
  //   highlight: "em medicina",
  //   button: {
  //     label: "Fazer minha inscrição",
  //     url: "https://app.olimpiadademedicina.org/",
  //   },
    
  // },
  // {
  //   image: sliderOne01,
  //   shape: shape01,
  //   subTitle: "Atual, integrada, completa e moderna",
  //   title: "A única olimpíada 100% \n focada",
  //   highlight: "em medicina",
  //   button: {
  //     label: "Fazer minha inscrição",
  //     url: "/about",
  //   },
  // }
];