/** @jsx jsx */
import React, { Fragment, useContext, useState, useEffect } from "react";
import { Global, css } from "@emotion/react"
import { Helmet } from "react-helmet";
import { MenuContext } from "@/context/menu-context";
import PopupMenu from "@/components/popup-menu";
import { Link as ScrollLink } from "react-scroll";
import { jsx } from '@emotion/react'
import { colors, whats } from "../assets/styles/layout.styles";
import AOS from 'aos'
import 'aos/dist/aos.css'
import Whatsapp from "@/images/LP-teamsix/whatsapp.svg"

// import "typeface-oswald";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/css/animate.css";
import "@/css/font-awesome.min.css";
import "@/css/icons.css";
// import "@/css/preset.css";
// import "@/css/theme.css";
// import "@/css/responsive.css";

import { showIt }  from '../assets/styles/layout.styles'
import { Link } from "gatsby";

const GlobalStyles = () => {
  return (
    <Global styles={css`
        @import url("https://use.typekit.net/vut2mra.css");
        @import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

        body, html{
            margin: 0;
            padding: 0;
            scroll-behavior: smooth;
            background-color:#f9f9f9;
            overflow-x: hidden;
        }
        body{
            line-height: 32px;
            color: ${colors.dark};
            letter-spacing: 0.64px;
            font-size: 24px;
            font-family: "geom-graphic", sans-serif;
            font-weight: 400;
        }

        /*------------------------------------------------------------------------------
        / 2. Typography Settings
        ------------------------------------------------------------------------------*/
        h1, h2, h3, h4, h5 h6{
            color: #000000;
            font-weight: 400;
            margin: 0 0 15px;
        }
        a{
            color: #000;
            text-decoration: none;
            transition: all ease 400ms;
            -webkit-transition: all ease 400ms;
            -moz-transition: all ease 400ms;
        }
        a:hover, a:focus{
            text-decoration: none;
            outline: 0;
        }
        a:focus{
            color: #fff;
        }
        ul li{
            margin: 0;
            padding: 0;
            list-style: none;
        }  

        @media(min-width: 1200px) {
            .container {
                max-width: 1200px;
            }
        }
    `} />
  )
}

const Layout = ({ PageTitle, children }) => {
  // const { searchStatus } = useContext(SearchContext);
  const { menuStatus } = useContext(MenuContext);
  const [scrollTop, setScrollTop] = useState(false);

  useEffect(() => {
    AOS.init({
      duration : 1000,
      once:true
    });
    AOS.refresh();
  }, []);

  const handleScrollTop = () => {
    if (window.scrollY > 70) {
      setScrollTop(true);
    } else if (window.scrollY < 70) {
      setScrollTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollTop);
    return () => {
      window.removeEventListener("scroll", handleScrollTop);
    };
  }, [scrollTop]);

  return (
    <Fragment>
      <Helmet>
        <title>
          {PageTitle}
        </title>
      </Helmet>
      <div style={{ overflowY: 'hidden'}} id="wrapper">
      <GlobalStyles />
        {children}</div>
      {/* {true === searchStatus ? <SearchPopup /> : null} */}
      {true === menuStatus ? <PopupMenu /> : null}

      {/* {scrollTop === true ? (
        <ScrollLink
            css={showIt}
          to="wrapper"
          smooth={true}
          duration={500}
          id="backToTop"
          className="scroll-to-top"
        >
          <i className="fa fa-angle-double-up"></i>
        </ScrollLink>
      ) : null} */}
      <a css={whats} href="https://web.whatsapp.com/send?phone=5551991709380&text=" target="_blank">
        <img src={Whatsapp} alt='whatsapp' />
      </a>
    </Fragment>
  );
};

export default Layout;
